import React from "react";

export const Block3 = (props) => {
  return (
    <section id="Block3" className="container mx-auto lg:max-w-screen-lg my-4">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
        <div className="text-center">
          <div className="text-violet-900 text-4xl text-center md:text-left md:text-7xl font-bold">
            Участвуйте
          </div>
          <div className="text-violet-900 text-3xl md:text-6xl text-center md:text-left font-bold">в розыгрыше</div>
          <div className="text-violet-900 text-3xl md:text-6xl text-center md:text-left font-bold">
            и выигрывайте
          </div>
          <div className="text-orange-300 hidden md:block my-5 text-16xl text-center md:text-left font-black leading-3 conture-orange wow">
            100
          </div>
          <div className="text-orange-300 sm:block md:hidden mt-10 mb-5 text-7xl text-center md:text-left font-black leading-3 conture-orange wow">
            100
          </div>
          <div className="text-orange-300 text-3xl md:text-9xl text-center md:text-left font-bold">призов</div>
        </div>
        <div className="px-3 hidden md:block">
          <img
            src={require("../img/4.png")}
            alt=""
            className="inset-0 -z-10 w-full object-right-bottom"
          />
        </div>
      </div>
    </section>
  );
};
