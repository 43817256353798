import React from "react";

export const Block5 = (props) => {
  return (
    <section id="Block5" className="overflow-hidden spectrum-background">
      <div class="container mx-auto lg:max-w-screen-lg my-4 py-4">
        <div class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-4">
          <div class="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
            <div className="text-orange-300 text-5xl md:text-6xl lg:text-6xl xl:text-6xl 2xl:text-6xl font-bold px-3">
              Проводим акцию с 2015 г
            </div>
            <div className="text-violet-900 text-4xl font-bold px-3 py-3">
              Посмотрите на счасливые лица наших победителей
            </div>
          </div>
          <div class="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2 mx-3">
            <img
              src={require("../img/11.png")}
              alt=""
              className="inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
            />
          </div>
          <div class="px-3">
            <img
              src={require("../img/22.png")}
              alt=""
              className="inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
            />
          </div>
          <div class="px-3">
            <img
              src={require("../img/33.png")}
              alt=""
              className="inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
            />
          </div>
          <div class="px-3 col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
            <img
              src={require("../img/44.png")}
              alt=""
              className="inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
            />
          </div>
          <div class="px-3 col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
            <img
              src={require("../img/55.png")}
              alt=""
              className="inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
            />
          </div>
          <div class="px-3">
            <img
              src={require("../img/66.png")}
              alt=""
              className="inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
            />
          </div>
          <div class="px-3">
            <img
              src={require("../img/77.png")}
              alt=""
              className="inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
