import { useState } from "react";

export const Block1 = (props) => {
  return (
    <section id="Block1" className="overflow-hidden">
      <div className="relative h-screen lg:h-5/6  z-10 overflow-hidden pt-[180px] pb-16 md:pt-[350px] md:pb-[120px] xl:pt-[480px] xl:pb-[160px] 2xl:pt-[710px] 2xl:pb-[200px]">
        <img
          src={require("../img/3.webp")}
          alt=""
          className="hidden md:block absolute inset-0 -z-10 h-full w-full object-cover object-top md:object-top"
        />
        <img
          src={require("../img/7.webp")}
          alt=""
          className="sm:block md:hidden absolute inset-0 -z-10 h-full w-full object-cover object-top md:object-top"
        />
        {/* Надпись на слайдере */}
        {/*<div className="mx-auto max-w-7xl">
          <div className="text-center mx-auto max-w-2xl">
            <h1 className="text-8xl font-bold tracking-tight text-gray-900 sm:text-8xl">
              Заголовок
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Подзаголовок
            </p>
          </div>
          <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Вступить в программу
              </a>
              <a
                href="#"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>*/}
      </div>
    </section>
  );
};
