import React, { useState, useEffect } from "react";
import { Header } from "../components/header";
import { Block1 } from "../components/Block1";
import { Block2 } from "../components/Block2";
import { Block3 } from "../components/Block3";
import { Block4 } from "../components/Block4";
import { Block5 } from "../components/Block5";
import { Block6 } from "../components/Block6";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Main = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Header data={landingPageData.Header} />
      <Block1 data={landingPageData.Hero} />
      <Block2 data={landingPageData.Services} />
      <Block3 data={landingPageData.About} />
      <Block4 />
      <Block5 />
      <Block6 />
    </div>
  );
};

export default Main;
