import React from "react";

export const Block6 = (props) => {
  return (
    <section
      id="Block6"
      className="container mx-auto lg:max-w-screen-lg my-4 pt-[20px] pb-[150px]"
    >
      <div class="my-4 flex justify-center pt-[100px] pb-[100px]">
        <a
          href="https://docs.google.com/document/d/1IhOByonHTPyhzYjwvl1LjlmvEfwyruGrmmL0a-_Lfy0/edit?usp=sharing"
          type="button"
          class="focus:outline-none bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900"
        >
          Подробные правила розыгрыша
        </a>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
        <div className="px-3">
          <img
            src={require("../img/6.png")}
            alt=""
            className="inset-0 -z-10 h-full w-full object-right md:object-center"
          />
        </div>
        <div>
          <div className="text-violet-900 text-2xl md:text-4xl font-bold px-4">
            На все вопросы ответит наш администратор на стойке информации в
            корпусе «А», 1 этаж
          </div>
          <div className="text-violet-900 md:text-2xl font-bold px-4 py-4">
            Или позвоните нам
          </div>
          <div className="text-orange-300 text-2xl md:text-4xl font-bold px-4">
            +7 (495) 785-34-34
          </div>
          <div className="text-orange-300 text-2xl md:text-4xl font-bold px-4">
            +7 (909) 937-29-60
          </div>
        </div>
      </div>
    </section>
  );
};
