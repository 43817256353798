import React from "react";

export const Block4 = (props) => {
  return (
    <section
      id="Block4"
      className="container mx-auto lg:max-w-screen-lg my-4 p-3 md:pt-[150px] md:pb-[150px]"
    >
      <div class="">
        <div className="">
          <div className="text-violet-900 text-xl lg:text-7xl font-bold text-center">
            <span>Главный приз</span><br />
            <span className="text-orange-300">cертификат</span>
          </div>
        </div>
        <div className="my-4 py-4 px-3">
          <img
            src={require("../img/5.png")}
            alt=""
            className="inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
          />
        </div>
      </div>
    </section>
  );
};
