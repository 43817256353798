import React from "react";

export const Block2 = (props) => {
  return (
    <section
      id="Block2"
      className="container mx-auto lg:max-w-screen-lg my-4 pt-[150px] pb-[150px]"
    >
      <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4">
        <div>
          <img
            src={require("../img/1.png")}
            alt=""
            className="inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
          />
        </div>
        <div>
          <img
            src={require("../img/2.png")}
            alt=""
            className="inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
          />
        </div>
        <div>
          <img
            src={require("../img/3.png")}
            alt=""
            className="inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
          />
        </div>
      </div>
    </section>
  );
};
